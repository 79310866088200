define("tournament-frontend/models/common/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultTees = _exports.defaultDynamics = _exports.defaultCourseSettings = _exports.defaultCalibration = void 0;
  const defaultDynamics = _exports.defaultDynamics = {
    gravity: 100,
    rollingResistance: 100,
    ballSpeedCompensation: 100,
    puttSpeedCompensation: 100,
    sliceSpinCompensation: 100,
    hookSpinCompensation: 100,
    backSpinCompensation: 100,
    forwardSpinCompensation: 100
  };
  const defaultCalibration = _exports.defaultCalibration = {
    ballSpeed: 100,
    puttingSpeed: 100,
    backSpinOffset: 0,
    sideSpinOffset: 0,
    backSpinGain: 100,
    sideSpinGain: 100
  };
  const defaultCourseSettings = _exports.defaultCourseSettings = {
    fieldDistance: 'Yard',
    greenDistance: 'Feet',
    speedUnit: 'MilePerHour',
    swingView: 'Hit5',
    puttingView: 'Putting1',
    xplateGreen: 'None',
    xplateField: 'None',
    autoBallFeed: 'Off',
    puttingPreview: 'Unlimited',
    conceed: 'SevenFeet',
    obTee: 'Off',
    playableArea: 'AlmostAll',
    doublePar: 'Off',
    windOption: 'Calm',
    clubSelectionInTheFridge: 'Putter',
    swingInformation: 'None',
    fieldTrajectory: 'All',
    greenCondition: 'Normal',
    puttingGrid: 'FastShaderGrid',
    autoFlyBy: 'On',
    rough: 90,
    greenSideBunker: 70,
    fairwayBunker: 90
  };
  const defaultTees = _exports.defaultTees = {
    maleTee: 'None',
    femaleTee: 'None',
    seniorTee: 'None'
  };
});