define("tournament-frontend/helpers/show-dashboard", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function showDashboard(positional) {
    if (positional[0].isSuperAdmin || positional[0].isBIAdmin) {
      return true;
    }
    return false;
  });
});