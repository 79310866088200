define("tournament-frontend/router", ["exports", "@ember/routing/router", "tournament-frontend/config/environment"], function (_exports, _router, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Router extends _router.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('signin');
    this.route('admin', function () {
      this.route('admins-list');
      this.route('tournaments-list');
      this.route('teams-list');
      this.route('locations-list');
      this.route('score-board');
      this.route('xcupseries-leaderboard');
      this.route('bi-dashboard');
      this.route('challenge');
    });
    this.route('leaderboard', {
      path: '/leaderboard/:tournament_id/:round'
    });
    this.route('challengeleaderboard', {
      path: '/challengeleaderboard/:challenge_id/'
    });
  });
});