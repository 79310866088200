define("tournament-frontend/routes/admin/bi-dashboard", ["exports", "@ember/routing/route", "@ember/service", "@glimmer/tracking", "tournament-frontend/models/bidashboard", "tournament-frontend/models/tournament", "@ember/array"], function (_exports, _route, _service, _tracking, _bidashboard, _tournament, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _class2, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdminBiDashboardRouteModel = (_class = class AdminBiDashboardRouteModel {
    constructor() {
      _initializerDefineProperty(this, "dashboardData", _descriptor, this);
      _initializerDefineProperty(this, "loading", _descriptor2, this);
      _initializerDefineProperty(this, "tournaments", _descriptor3, this);
      _initializerDefineProperty(this, "teeSettingsLabels", _descriptor4, this);
      _initializerDefineProperty(this, "bidashboardService", _descriptor5, this);
    }
    setDashboardData({
      gamesPerMode,
      gamesPerCourse,
      activeTournaments,
      tournaments,
      holeDurations,
      avgPlayerNumberByTournament,
      numberOfAppUsers,
      gameDemographics,
      numberOfPlays,
      numberOfTournaments,
      numberOfUsers,
      numberOfPlayers,
      avgPlayerNumber,
      teamNumberPerTournament,
      gameScoresPerCourse,
      gameScores,
      generalTeeSettings,
      numberOfPlaysPerMonth,
      averageTimeOnSimulator
    }) {
      this.dashboardData = new _bidashboard.default(this.convertGamesPerMode(gamesPerMode), this.convertGamesPerCourse(gamesPerCourse), this.convertActiveTournaments(activeTournaments), this.convertTournaments(tournaments), this.convertHoleDurations(holeDurations), this.convertAvgPlayerNumberByTournament(avgPlayerNumberByTournament), numberOfAppUsers,
      //
      this.convertGameDemographicsOverall(gameDemographics), this.convertGameDemographicsScratch(gameDemographics), this.convertGameDemographicsLow(gameDemographics), this.convertGameDemographicsMedium(gameDemographics), this.convertGameDemographicsHigh(gameDemographics),
      //
      this.convertGenderInfoOverall(gameDemographics), this.convertGenderInfoScratch(gameDemographics), this.convertGenderInfoLow(gameDemographics), this.convertGenderInfoMedium(gameDemographics), this.convertGenderInfoHigh(gameDemographics),
      //
      this.convertTeeColorsOverall(gameDemographics), this.convertTeeColorsScratch(gameDemographics), this.convertTeeColorsLow(gameDemographics), this.convertTeeColorsMedium(gameDemographics), this.convertTeeColorsHigh(gameDemographics),
      //
      this.convertHandednessInfoOverall(gameDemographics), this.convertHandednessInfoScratch(gameDemographics), this.convertHandednessInfoLow(gameDemographics), this.convertHandednessInfoMedium(gameDemographics), this.convertHandednessInfoHigh(gameDemographics),
      //
      this.convertAgeGroupingStatsOverall(gameDemographics), this.convertAgeGroupingStatsScratch(gameDemographics), this.convertAgeGroupingStatsLow(gameDemographics), this.convertAgeGroupingStatsMedium(gameDemographics), this.convertAgeGroupingStatsHigh(gameDemographics),
      //
      numberOfPlays, numberOfTournaments, numberOfUsers, numberOfPlayers, avgPlayerNumber, this.convertHoleDurationsPer(holeDurations), this.convertTeamNumberPerTournament(teamNumberPerTournament), this.convertGameScoresPerCourse(gameScoresPerCourse),
      //game scores
      this.convertGameScoresOverall(gameScores), this.convertGameScoresScratch(gameScores), this.convertGameScoresLow(gameScores), this.convertGameScoresMedium(gameScores), this.convertGameScoresHigh(gameScores),
      //average score per hole
      this.convertAverageScorePerHoleOverall(gameScores), this.convertAverageScorePerHoleScratch(gameScores), this.convertAverageScorePerHoleLow(gameScores), this.convertAverageScorePerHoleMedium(gameScores), this.convertAverageScorePerHoleHigh(gameScores),
      //tee settings
      this.convertTeeSettings(generalTeeSettings), this.convertNumberOfPlaysPerMonth(numberOfPlaysPerMonth), this.convertAverageScorePerCourse(gameScoresPerCourse), this.convertAverageScorePerHole(gameScores), averageTimeOnSimulator);
      this.loading = false;
    }
    convertHoleDurations(holeDurations) {
      return this.bidashboardService.getAverageTimePerHoleBoardData(holeDurations);
    }
    convertGamesPerMode(gamesPerMode) {
      return this.bidashboardService.getMostPopularGameModesBoardData(gamesPerMode);
    }
    convertGamesPerCourse(gamesPerCourse) {
      return this.bidashboardService.getMostPlayedCoursesBoardData(gamesPerCourse);
    }
    convertTournaments(tournaments) {
      this.tournaments = (0, _array.A)(tournaments.map(a => new _tournament.default(a)));
    }
    convertActiveTournaments(activeTournaments) {
      return this.bidashboardService.getTournamentActiveDaysBoardData(activeTournaments);
    }
    convertAvgPlayerNumberByTournament(avgPlayerNumberByTournament) {
      return {
        labels: avgPlayerNumberByTournament?.map(x => x.tournamentName),
        datasets: [{
          label: 'Average Player',
          data: avgPlayerNumberByTournament?.map(x => x.averagePlayer),
          backgroundColor: ['rgba(0, 145, 213, 0.8)', 'rgba(234, 106, 71, 0.8)', 'rgba(165, 216, 221, 0.8)', 'rgba(28, 78, 128, 0.8)', 'rgba(126, 144, 154, 0.8)', 'rgba(32, 32, 32, 0.8)', 'rgba(241, 241, 241, 0.8)']
        }]
      };
    }
    convertGameDemographicsOverall(gameDemographics) {
      for (let i = 0; i < gameDemographics.length; i++) {
        if (gameDemographics[i].playerClassification == 'OVERALL') {
          return gameDemographics[i];
        }
      }
    }
    convertGameDemographicsScratch(gameDemographics) {
      for (let i = 0; i < gameDemographics.length; i++) {
        if (gameDemographics[i].playerClassification == 'SCRATCH') {
          return gameDemographics[i];
        }
      }
    }
    convertGameDemographicsLow(gameDemographics) {
      for (let i = 0; i < gameDemographics.length; i++) {
        if (gameDemographics[i].playerClassification == 'LOW') {
          return gameDemographics[i];
        }
      }
    }
    convertGameDemographicsMedium(gameDemographics) {
      for (let i = 0; i < gameDemographics.length; i++) {
        if (gameDemographics[i].playerClassification == 'MEDIUM') {
          return gameDemographics[i];
        }
      }
    }
    convertGameDemographicsHigh(gameDemographics) {
      for (let i = 0; i < gameDemographics.length; i++) {
        if (gameDemographics[i].playerClassification == 'HIGH') {
          return gameDemographics[i];
        }
      }
    }
    getGenderInfoData(gameDemographics, demographicsType) {
      for (let i = 0; i < gameDemographics.length; i++) {
        if (gameDemographics[i].playerClassification == demographicsType) {
          return {
            labels: ['Male', 'Female'],
            datasets: [{
              label: '#',
              data: [gameDemographics[i].totalMaleNumber, gameDemographics[i].totalFemaleNumber],
              backgroundColor: ['rgba(245, 221, 139, 0.8)', 'rgba(76, 80, 85, 0.8)'],
              borderWidth: 1
            }]
          };
        }
      }
      return null;
    }
    convertGenderInfoOverall(gameDemographics) {
      return this.getGenderInfoData(gameDemographics, 'OVERALL');
    }
    convertGenderInfoScratch(gameDemographics) {
      return this.getGenderInfoData(gameDemographics, 'SCRATCH');
    }
    convertGenderInfoLow(gameDemographics) {
      return this.getGenderInfoData(gameDemographics, 'LOW');
    }
    convertGenderInfoMedium(gameDemographics) {
      return this.getGenderInfoData(gameDemographics, 'MEDIUM');
    }
    convertGenderInfoHigh(gameDemographics) {
      return this.getGenderInfoData(gameDemographics, 'HIGH');
    }
    getTeeColorsData(gameDemographics, demographicsType) {
      let teeColorsData;
      for (let i = 0; i < gameDemographics.length; i++) {
        if (gameDemographics[i].playerClassification == demographicsType) {
          teeColorsData = gameDemographics[i].teeColors.filter(el => el.teeColorName !== 'None');
        }
      }
      return {
        labels: teeColorsData?.map(x => x.teeColorName),
        datasets: [{
          label: '# of Golfer',
          data: teeColorsData?.map(x => x.totalOccurrence),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    convertTeeColorsOverall(gameDemographics) {
      return this.getTeeColorsData(gameDemographics, 'OVERALL');
    }
    convertTeeColorsScratch(gameDemographics) {
      return this.getTeeColorsData(gameDemographics, 'SCRATCH');
    }
    convertTeeColorsLow(gameDemographics) {
      return this.getTeeColorsData(gameDemographics, 'LOW');
    }
    convertTeeColorsMedium(gameDemographics) {
      return this.getTeeColorsData(gameDemographics, 'MEDIUM');
    }
    convertTeeColorsHigh(gameDemographics) {
      return this.getTeeColorsData(gameDemographics, 'HIGH');
    }
    getHandednessInfoData(gameDemographics, demographicsType) {
      for (let i = 0; i < gameDemographics.length; i++) {
        if (gameDemographics[i].playerClassification == demographicsType) {
          return {
            labels: ['Left', 'Right'],
            datasets: [{
              label: '#',
              data: [gameDemographics[i].totalLeftHanded, gameDemographics[i].totalRightHanded],
              backgroundColor: ['rgba(234, 134, 0, 0.8)', 'rgba(131, 208, 230, 0.8)'],
              borderWidth: 1
            }]
          };
        }
      }
      return null;
    }
    convertHandednessInfoOverall(gameDemographics) {
      return this.getHandednessInfoData(gameDemographics, 'OVERALL');
    }
    convertHandednessInfoScratch(gameDemographics) {
      return this.getHandednessInfoData(gameDemographics, 'SCRATCH');
    }
    convertHandednessInfoLow(gameDemographics) {
      return this.getHandednessInfoData(gameDemographics, 'LOW');
    }
    convertHandednessInfoMedium(gameDemographics) {
      return this.getHandednessInfoData(gameDemographics, 'MEDIUM');
    }
    convertHandednessInfoHigh(gameDemographics) {
      return this.getHandednessInfoData(gameDemographics, 'HIGH');
    }
    getAgeGroupingStatsData(gameDemographics, demographicsType) {
      var sorted = null;
      for (let i = 0; i < gameDemographics.length; i++) {
        if (gameDemographics[i].playerClassification == demographicsType) {
          sorted = gameDemographics[i].ageGroupingStats.sort(function (a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          });
        }
      }
      return sorted;
    }
    convertAgeGroupingStatsOverall(gameDemographics) {
      var sorted = this.getAgeGroupingStatsData(gameDemographics, 'OVERALL');
      return {
        labels: sorted?.map(x => x.name),
        datasets: [{
          label: '# of Golfers',
          data: sorted?.map(x => x.occurrence),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    convertAgeGroupingStatsScratch(gameDemographics) {
      var sorted = this.getAgeGroupingStatsData(gameDemographics, 'SCRATCH');
      return {
        labels: sorted?.map(x => x.name),
        datasets: [{
          label: '# of Golfers',
          data: sorted?.map(x => x.occurrence),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    convertAgeGroupingStatsLow(gameDemographics) {
      var sorted = this.getAgeGroupingStatsData(gameDemographics, 'LOW');
      return {
        labels: sorted?.map(x => x.name),
        datasets: [{
          label: '# of Golfers',
          data: sorted?.map(x => x.occurrence),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    convertAgeGroupingStatsMedium(gameDemographics) {
      var sorted = this.getAgeGroupingStatsData(gameDemographics, 'MEDIUM');
      return {
        labels: sorted?.map(x => x.name),
        datasets: [{
          label: '# of Golfers',
          data: sorted?.map(x => x.occurrence),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    convertAgeGroupingStatsHigh(gameDemographics) {
      var sorted = this.getAgeGroupingStatsData(gameDemographics, 'HIGH');
      return {
        labels: sorted?.map(x => x.name),
        datasets: [{
          label: '# of Golfers',
          data: sorted?.map(x => x.occurrence),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    convertHoleDurationsPer(holeDurations) {
      let data = holeDurations?.map(x => x.averageDuration);
      return {
        per9: data.at(18),
        per18: data.at(19)
      };
    }
    convertTeamNumberPerTournament(teamNumberPerTournament) {
      return this.bidashboardService.getTeamNumberPerTournamentBoardData(teamNumberPerTournament);
    }
    convertGameScoresPerCourse(gameScoresPerCourse) {
      return {
        labels: gameScoresPerCourse?.map(x => x.gameCourse),
        datasets: [{
          label: 'Average Score',
          data: gameScoresPerCourse?.map(x => x.averageScore),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    getGameScores(gameScores, playerClassification) {
      for (let i = 0; i < gameScores.length; i++) {
        if (gameScores[i].playerClassification == playerClassification) {
          return gameScores[i];
        }
      }
    }
    convertGameScoresOverall(gameScores) {
      return this.getGameScores(gameScores, 'OVERALL');
    }
    convertGameScoresScratch(gameScores) {
      return this.getGameScores(gameScores, 'SCRATCH');
    }
    convertGameScoresLow(gameScores) {
      return this.getGameScores(gameScores, 'LOW');
    }
    convertGameScoresMedium(gameScores) {
      return this.getGameScores(gameScores, 'MEDIUM');
    }
    convertGameScoresHigh(gameScores) {
      return this.getGameScores(gameScores, 'HIGH');
    }
    getHoleScores(gameScores, playerClassification) {
      let holeScoresData;
      for (let i = 0; i < gameScores.length; i++) {
        if (gameScores[i].playerClassification == playerClassification) {
          holeScoresData = gameScores[i].holeScoreDtos;
        }
      }
      return holeScoresData;
    }
    getAverageScoresPerHoleData(gameScores, playerClassification) {
      var holeScoresData = this.getHoleScores(gameScores, playerClassification);
      return {
        labels: holeScoresData?.map(x => x.holeNumber),
        datasets: [{
          label: 'Average Score',
          data: holeScoresData?.map(x => x.averageScore),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    convertAverageScorePerHoleOverall(gameScores) {
      return this.getAverageScoresPerHoleData(gameScores, 'OVERALL');
    }
    convertAverageScorePerHoleScratch(gameScores) {
      return this.getAverageScoresPerHoleData(gameScores, 'SCRATCH');
    }
    convertAverageScorePerHoleLow(gameScores) {
      return this.getAverageScoresPerHoleData(gameScores, 'LOW');
    }
    convertAverageScorePerHoleMedium(gameScores) {
      return this.getAverageScoresPerHoleData(gameScores, 'MEDIUM');
    }
    convertAverageScorePerHoleHigh(gameScores) {
      var high = this.getAverageScoresPerHoleData(gameScores, 'HIGH');
      return high;
    }
    convertTeeSettings(generalTeeSettings) {
      return this.bidashboardService.getTournamentTeeSettingsBoardData(generalTeeSettings);
    }
    convertNumberOfPlaysPerMonth(numberOfPlaysPerMonth) {
      return this.bidashboardService.getNumberOfPlaysPerMonthBoardData(numberOfPlaysPerMonth);
    }
    convertAverageScorePerCourse(gameScoresPerCourse) {
      var scores = gameScoresPerCourse?.map(x => x.averageScore);
      var total = scores.reduce((a, b) => a + b, 0);
      var average = total / scores.length || 0;
      return average;
    }
    convertAverageScorePerHole(gameScores) {
      var overallHoleData = this.getHoleScores(gameScores, 'OVERALL');
      var overallAverageHoleScores = overallHoleData?.map(x => x.averageScore);
      var total = overallAverageHoleScores.reduce((a, b) => a + b, 0);
      var average = total / overallAverageHoleScores.length || 0;
      return average;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dashboardData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tournaments", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "teeSettingsLabels", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "bidashboardService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  let AdminBiDashboardRoute = _exports.default = (_class2 = class AdminBiDashboardRoute extends _route.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "requests", _descriptor6, this);
      _initializerDefineProperty(this, "biDashboard", _descriptor7, this);
    }
    async model() {
      let dashboardData = new AdminBiDashboardRouteModel();
      dashboardData.loading = true;
      dashboardData.bidashboardService = this.biDashboard;
      this.getData(dashboardData);
      return dashboardData;
    }
    async getData(dashboardData) {
      dashboardData.setDashboardData({
        gamesPerMode: await this.biDashboard.getMostPopularGameModes(null, null),
        gamesPerCourse: await this.biDashboard.getMostPlayedCourses(null, null),
        activeTournaments: await this.biDashboard.getTournamentActiveDays(null, null),
        tournaments: await this.requests.make('/alltournaments', 'get'),
        holeDurations: await this.biDashboard.getAverageTimePerHole(null, null),
        avgPlayerNumberByTournament: await this.requests.make('/bi-dashboard/avg-player-number-per-tournament', 'get'),
        numberOfAppUsers: await this.requests.make('/bi-dashboard/number-of-app-users', 'get'),
        gameDemographics: await this.requests.make('/bi-dashboard/games-demographics', 'get'),
        numberOfPlays: await this.requests.make('/bi-dashboard/number-of-plays', 'get'),
        numberOfTournaments: await this.requests.make('/bi-dashboard/number-of-tournaments', 'get'),
        numberOfUsers: await this.requests.make('/bi-dashboard/number-of-users', 'get'),
        numberOfPlayers: await this.biDashboard.getNumberOfPlayers(null, null),
        avgPlayerNumber: await this.requests.make('/bi-dashboard/avg-player-number', 'get'),
        teamNumberPerTournament: await this.biDashboard.getTeamNumberPerTournament(null, null),
        gameScoresPerCourse: await this.requests.make('/bi-dashboard/game-scores-per-course', 'get'),
        gameScores: await this.requests.make('/bi-dashboard/game-scores', 'get'),
        generalTeeSettings: await this.biDashboard.getTournamentTeeSettings(null, null),
        numberOfPlaysPerMonth: await this.biDashboard.getNumberOfPlaysPerMonth(null, null),
        averageTimeOnSimulator: await this.requests.make('/bi-dashboard/avg-time-simulator', 'get')
      });
    }
  }, (_descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "requests", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "biDashboard", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);
});