define("tournament-frontend/models/team", ["exports", "@glimmer/tracking", "@ember/array"], function (_exports, _tracking, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let Team = _exports.default = (_class = class Team {
    constructor({
      id = 0,
      name = '',
      tournamentId = 0,
      passCode = '',
      currentRound = 1,
      location = '',
      updateBestScoreRequest = 0,
      teamPassCode = '',
      emailsList = (0, _array.A)([]),
      hidden = true
    }) {
      _initializerDefineProperty(this, "id", _descriptor, this);
      _initializerDefineProperty(this, "name", _descriptor2, this);
      _initializerDefineProperty(this, "tournamentId", _descriptor3, this);
      _initializerDefineProperty(this, "passCode", _descriptor4, this);
      _initializerDefineProperty(this, "currentRound", _descriptor5, this);
      _initializerDefineProperty(this, "location", _descriptor6, this);
      _initializerDefineProperty(this, "updateBestScoreRequest", _descriptor7, this);
      _initializerDefineProperty(this, "emailsList", _descriptor8, this);
      _initializerDefineProperty(this, "hidden", _descriptor9, this);
      this.id = id;
      this.name = name;
      this.tournamentId = tournamentId;
      this.passCode = passCode;
      this.currentRound = currentRound;
      this.location = location;
      this.updateBestScoreRequest = updateBestScoreRequest;
      this.teamPassCode = teamPassCode;
      this.emailsList = (0, _array.A)([...emailsList]);
      this.hidden = hidden;
    }
    get json() {
      return {
        id: this.id,
        name: this.name,
        tournamentId: this.tournamentId,
        passCode: this.passCode,
        currentRound: this.currentRound,
        location: this.location,
        updateBestScoreRequest: this.updateBestScoreRequest,
        teamPassCode: this.teamPassCode,
        emailsList: this.emailsList,
        hidden: this.hidden
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tournamentId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "passCode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentRound", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "location", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "updateBestScoreRequest", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "emailsList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hidden", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});