define("tournament-frontend/models/bidashboard", ["exports", "@glimmer/tracking"], function (_exports, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52, _descriptor53;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let BiDashboard = _exports.default = (_class = class BiDashboard {
    constructor(playModeData = {
      labels: [],
      datasets: []
    }, mostPlayedCoursesData = {
      labels: [],
      datasets: []
    }, activeTournamentsData = {
      labels: [],
      datasets: []
    }, tournamentsData = {}, holeDurationsData = {
      labels: [],
      datasets: []
    }, avgPlayerNumberByTournamentData = {
      labels: [],
      datasets: []
    }, numberOfAppUsersData = 0, gameDemographicsOverallData = {}, gameDemographicsScratchData = {}, gameDemographicsLowData = {}, gameDemographicsMediumData = {}, gameDemographicsHighData = {}, genderInfoOverallData = {
      labels: [],
      datasets: []
    }, genderInfoScratchData = {
      labels: [],
      datasets: []
    }, genderInfoLowData = {
      labels: [],
      datasets: []
    }, genderInfoMediumData = {
      labels: [],
      datasets: []
    }, genderInfoHighData = {
      labels: [],
      datasets: []
    }, teeColorsOverallData = {
      labels: [],
      datasets: []
    }, teeColorsScratchData = {
      labels: [],
      datasets: []
    }, teeColorsLowData = {
      labels: [],
      datasets: []
    }, teeColorsMediumData = {
      labels: [],
      datasets: []
    }, teeColorsHighData = {
      labels: [],
      datasets: []
    }, handednessInfoOverallData = {
      labels: [],
      datasets: []
    }, handednessInfoScratchData = {
      labels: [],
      datasets: []
    }, handednessInfoLowData = {
      labels: [],
      datasets: []
    }, handednessInfoMediumData = {
      labels: [],
      datasets: []
    }, handednessInfoHighData = {
      labels: [],
      datasets: []
    }, ageGroupingStatsOverallData = {
      labels: [],
      datasets: []
    }, ageGroupingStatsScratchData = {
      labels: [],
      datasets: []
    }, ageGroupingStatsLowData = {
      labels: [],
      datasets: []
    }, ageGroupingStatsMediumData = {
      labels: [],
      datasets: []
    }, ageGroupingStatsHighData = {
      labels: [],
      datasets: []
    }, numberOfPlaysData = {}, numberOfTournamentsData = {}, numberOfUsersData = {}, numberOfPlayersData = {}, avgPlayerNumberData = {}, holeDurationsPerData = {
      per9,
      per18
    }, teamNumberPerTournamentData = {
      labels: [],
      datasets: []
    }, gameScoresPerCourseData = {
      labels: [],
      datasets: []
    }, gameScoresOverallData = {}, gameScoresScratchData = {}, gameScoresLowData = {}, gameScoresMediumData = {}, gameScoresHighData = {}, averageScorePerHoleOverallData = {
      labels: [],
      datasets: []
    }, averageScorePerHoleScratchData = {
      labels: [],
      datasets: []
    }, averageScorePerHoleLowData = {
      labels: [],
      datasets: []
    }, averageScorePerHoleMediumData = {
      labels: [],
      datasets: []
    }, averageScorePerHoleHighData = {
      labels: [],
      datasets: []
    }, teeSettingsData = {
      labels: [],
      datasets: []
    }, numberOfPlaysPerMonthData = {
      labels: [],
      datasets: []
    }, averageGameScorePerCourseData = 0, averageGameScorePerHoleData = 0, averageTimeOnSimulatorData = 0) {
      _initializerDefineProperty(this, "playModeData", _descriptor, this);
      _initializerDefineProperty(this, "mostPlayedCoursesData", _descriptor2, this);
      _initializerDefineProperty(this, "activeTournamentsData", _descriptor3, this);
      _initializerDefineProperty(this, "tournamentsData", _descriptor4, this);
      _initializerDefineProperty(this, "holeDurationsData", _descriptor5, this);
      _initializerDefineProperty(this, "avgPlayerNumberByTournamentData", _descriptor6, this);
      _initializerDefineProperty(this, "numberOfAppUsersData", _descriptor7, this);
      // game demographics 
      _initializerDefineProperty(this, "gameDemographicsOverallData", _descriptor8, this);
      _initializerDefineProperty(this, "gameDemographicsScratchData", _descriptor9, this);
      _initializerDefineProperty(this, "gameDemographicsLowData", _descriptor10, this);
      _initializerDefineProperty(this, "gameDemographicsMediumData", _descriptor11, this);
      _initializerDefineProperty(this, "gameDemographicsHighData", _descriptor12, this);
      // gender info
      _initializerDefineProperty(this, "genderInfoOverallData", _descriptor13, this);
      _initializerDefineProperty(this, "genderInfoScratchData", _descriptor14, this);
      _initializerDefineProperty(this, "genderInfoLowData", _descriptor15, this);
      _initializerDefineProperty(this, "genderInfoMediumData", _descriptor16, this);
      _initializerDefineProperty(this, "genderInfoHighData", _descriptor17, this);
      // tee colors
      _initializerDefineProperty(this, "teeColorsOverallData", _descriptor18, this);
      _initializerDefineProperty(this, "teeColorsScratchData", _descriptor19, this);
      _initializerDefineProperty(this, "teeColorsLowData", _descriptor20, this);
      _initializerDefineProperty(this, "teeColorsMediumData", _descriptor21, this);
      _initializerDefineProperty(this, "teeColorsHighData", _descriptor22, this);
      // handedness info
      _initializerDefineProperty(this, "handednessInfoOverallData", _descriptor23, this);
      _initializerDefineProperty(this, "handednessInfoScratchData", _descriptor24, this);
      _initializerDefineProperty(this, "handednessInfoLowData", _descriptor25, this);
      _initializerDefineProperty(this, "handednessInfoMediumData", _descriptor26, this);
      _initializerDefineProperty(this, "handednessInfoHighData", _descriptor27, this);
      // age group stats
      _initializerDefineProperty(this, "ageGroupingStatsOverallData", _descriptor28, this);
      _initializerDefineProperty(this, "ageGroupingStatsScratchData", _descriptor29, this);
      _initializerDefineProperty(this, "ageGroupingStatsLowData", _descriptor30, this);
      _initializerDefineProperty(this, "ageGroupingStatsMediumData", _descriptor31, this);
      _initializerDefineProperty(this, "ageGroupingStatsHighData", _descriptor32, this);
      //  
      _initializerDefineProperty(this, "numberOfPlaysData", _descriptor33, this);
      _initializerDefineProperty(this, "numberOfTournamentsData", _descriptor34, this);
      _initializerDefineProperty(this, "numberOfUsersData", _descriptor35, this);
      _initializerDefineProperty(this, "numberOfPlayersData", _descriptor36, this);
      _initializerDefineProperty(this, "teamNumberPerTournamentData", _descriptor37, this);
      _initializerDefineProperty(this, "gameScoresPerCourseData", _descriptor38, this);
      // game scores 
      _initializerDefineProperty(this, "gameScoresOverallData", _descriptor39, this);
      _initializerDefineProperty(this, "gameScoresScratchData", _descriptor40, this);
      _initializerDefineProperty(this, "gameScoresLowData", _descriptor41, this);
      _initializerDefineProperty(this, "gameScoresMediumData", _descriptor42, this);
      _initializerDefineProperty(this, "gameScoresHighData", _descriptor43, this);
      //average scores per hole
      _initializerDefineProperty(this, "averageScorePerHoleOverallData", _descriptor44, this);
      _initializerDefineProperty(this, "averageScorePerHoleScratchData", _descriptor45, this);
      _initializerDefineProperty(this, "averageScorePerHoleLowData", _descriptor46, this);
      _initializerDefineProperty(this, "averageScorePerHoleMediumData", _descriptor47, this);
      _initializerDefineProperty(this, "averageScorePerHoleHighData", _descriptor48, this);
      // tee settings
      _initializerDefineProperty(this, "teeSettingsData", _descriptor49, this);
      // number of plays per month
      _initializerDefineProperty(this, "numberOfPlaysPerMonthData", _descriptor50, this);
      //average game scores
      _initializerDefineProperty(this, "averageGameScorePerCourseData", _descriptor51, this);
      _initializerDefineProperty(this, "averageGameScorePerHoleData", _descriptor52, this);
      //average time on simulator
      _initializerDefineProperty(this, "averageTimeOnSimulatorData", _descriptor53, this);
      this.playModeData = playModeData;
      this.mostPlayedCoursesData = mostPlayedCoursesData;
      this.activeTournamentsData = activeTournamentsData;
      this.tournamentsData = tournamentsData;
      this.holeDurationsData = holeDurationsData;
      this.avgPlayerNumberByTournamentData = avgPlayerNumberByTournamentData;
      this.numberOfAppUsersData = numberOfAppUsersData;
      // game demographics
      this.gameDemographicsOverallData = gameDemographicsOverallData;
      this.gameDemographicsScratchData = gameDemographicsScratchData;
      this.gameDemographicsLowData = gameDemographicsLowData;
      this.gameDemographicsMediumData = gameDemographicsMediumData;
      this.gameDemographicsHighData = gameDemographicsHighData;
      // gender info
      this.genderInfoOverallData = genderInfoOverallData;
      this.genderInfoScratchData = genderInfoScratchData;
      this.genderInfoLowData = genderInfoLowData;
      this.genderInfoMediumData = genderInfoMediumData;
      this.genderInfoHighData = genderInfoHighData;
      // tee colors
      this.teeColorsOverallData = teeColorsOverallData;
      this.teeColorsScratchData = teeColorsScratchData;
      this.teeColorsLowData = teeColorsLowData;
      this.teeColorsMediumData = teeColorsMediumData;
      this.teeColorsHighData = teeColorsHighData;
      // handedness info
      this.handednessInfoOverallData = handednessInfoOverallData;
      this.handednessInfoScratchData = handednessInfoScratchData;
      this.handednessInfoLowData = handednessInfoLowData;
      this.handednessInfoMediumData = handednessInfoMediumData;
      this.handednessInfoHighData = handednessInfoHighData;
      // age grouping stats
      this.ageGroupingStatsOverallData = ageGroupingStatsOverallData;
      this.ageGroupingStatsScratchData = ageGroupingStatsScratchData;
      this.ageGroupingStatsLowData = ageGroupingStatsLowData;
      this.ageGroupingStatsMediumData = ageGroupingStatsMediumData;
      this.ageGroupingStatsHighData = ageGroupingStatsHighData;
      //
      this.numberOfPlaysData = numberOfPlaysData;
      this.numberOfTournamentsData = numberOfTournamentsData;
      this.numberOfUsersData = numberOfUsersData;
      this.numberOfPlayersData = numberOfPlayersData;
      this.avgPlayerNumberData = avgPlayerNumberData;
      this.holeDurationsPerData = holeDurationsPerData;
      this.teamNumberPerTournamentData = teamNumberPerTournamentData;
      this.gameScoresPerCourseData = gameScoresPerCourseData;
      // game scores
      this.gameScoresOverallData = gameScoresOverallData;
      this.gameScoresScratchData = gameScoresScratchData;
      this.gameScoresLowData = gameScoresLowData;
      this.gameScoresMediumData = gameScoresMediumData;
      this.gameScoresHighData = gameScoresHighData;
      // average scores per hole
      this.averageScorePerHoleOverallData = averageScorePerHoleOverallData;
      this.averageScorePerHoleScratchData = averageScorePerHoleScratchData;
      this.averageScorePerHoleLowData = averageScorePerHoleLowData;
      this.averageScorePerHoleMediumData = averageScorePerHoleMediumData;
      this.averageScorePerHoleHighData = averageScorePerHoleHighData;
      //tee settings
      this.teeSettingsData = teeSettingsData;
      //number of plays per month
      this.numberOfPlaysPerMonthData = numberOfPlaysPerMonthData;
      //average game scores
      this.averageGameScorePerCourseData = averageGameScorePerCourseData;
      this.averageGameScorePerHoleData = averageGameScorePerHoleData;
      //average time on simulator
      this.averageTimeOnSimulatorData = averageTimeOnSimulatorData;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "playModeData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mostPlayedCoursesData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "activeTournamentsData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tournamentsData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "holeDurationsData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "avgPlayerNumberByTournamentData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "numberOfAppUsersData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "gameDemographicsOverallData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "gameDemographicsScratchData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "gameDemographicsLowData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "gameDemographicsMediumData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "gameDemographicsHighData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "genderInfoOverallData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "genderInfoScratchData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "genderInfoLowData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "genderInfoMediumData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "genderInfoHighData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "teeColorsOverallData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "teeColorsScratchData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "teeColorsLowData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "teeColorsMediumData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "teeColorsHighData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "handednessInfoOverallData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "handednessInfoScratchData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "handednessInfoLowData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "handednessInfoMediumData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "handednessInfoHighData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "ageGroupingStatsOverallData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "ageGroupingStatsScratchData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "ageGroupingStatsLowData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "ageGroupingStatsMediumData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "ageGroupingStatsHighData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "numberOfPlaysData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "numberOfTournamentsData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "numberOfUsersData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "numberOfPlayersData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "teamNumberPerTournamentData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "gameScoresPerCourseData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "gameScoresOverallData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "gameScoresScratchData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "gameScoresLowData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "gameScoresMediumData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "gameScoresHighData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "averageScorePerHoleOverallData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "averageScorePerHoleScratchData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "averageScorePerHoleLowData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "averageScorePerHoleMediumData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = _applyDecoratedDescriptor(_class.prototype, "averageScorePerHoleHighData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = _applyDecoratedDescriptor(_class.prototype, "teeSettingsData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = _applyDecoratedDescriptor(_class.prototype, "numberOfPlaysPerMonthData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = _applyDecoratedDescriptor(_class.prototype, "averageGameScorePerCourseData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = _applyDecoratedDescriptor(_class.prototype, "averageGameScorePerHoleData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor53 = _applyDecoratedDescriptor(_class.prototype, "averageTimeOnSimulatorData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});