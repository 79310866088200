define("tournament-frontend/controllers/admin/score-board", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "tournament-frontend/resources/enums"], function (_exports, _controller, _object, _service, _tracking, _enums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  /* global chart */
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdminScoreBoardController = _exports.default = (_class = class AdminScoreBoardController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "modals", _descriptor, this);
      _initializerDefineProperty(this, "requests", _descriptor2, this);
      _initializerDefineProperty(this, "notifications", _descriptor3, this);
      _initializerDefineProperty(this, "selectedTournament", _descriptor4, this);
      _initializerDefineProperty(this, "selectedTeam", _descriptor5, this);
      _initializerDefineProperty(this, "selectedTeamName", _descriptor6, this);
      _initializerDefineProperty(this, "selectedRound", _descriptor7, this);
      _initializerDefineProperty(this, "loading", _descriptor8, this);
      _initializerDefineProperty(this, "errors", _descriptor9, this);
      _initializerDefineProperty(this, "teams", _descriptor10, this);
      _initializerDefineProperty(this, "rounds", _descriptor11, this);
      _initializerDefineProperty(this, "hasScoreBoard", _descriptor12, this);
      _initializerDefineProperty(this, "scoreCard", _descriptor13, this);
      _initializerDefineProperty(this, "scoreTournamentPlayMode", _descriptor14, this);
      _initializerDefineProperty(this, "selectedPlayertoEditHandicap", _descriptor15, this);
      _initializerDefineProperty(this, "teamHandicap", _descriptor16, this);
    }
    tournamentChanged() {
      this.selectedTournament = event.target.value;
      this.hasScoreBoard = false;
      this.selectedTeam = 0;
      this.selectedRound = 0;
      this.teams = [];
      this.rounds = [];
      if (this.selectedTournament != 0) {
        this.requests.make(`/teams/${this.selectedTournament}`, 'get').then(response => {
          this.loading = false;
          this.teams = response;
        }).catch(() => {});
      }
    }
    teamChanged() {
      this.selectedTeam = event.target.value;
      this.hasScoreBoard = false;
      this.selectedRound = 0;
      this.rounds = [];
      if (this.selectedTeam != 0) {
        let currentTeam = this.teams.filter(team => {
          return team.id == this.selectedTeam;
        });
        this.selectedTeamName = currentTeam[0].name;
        for (let i = 1; i <= currentTeam[0].currentRound; i++) {
          this.rounds.push(i);
        }
      }
    }
    roundChanged() {
      this.selectedRound = event.target.value;
      this.getScoreCardandShow();
    }
    getScoreCardandShow() {
      this.hasScoreBoard = false;
      if (this.selectedRound != 0) {
        this.requests.make(`/getTeamScoreBoard/${this.selectedTournament}/${this.selectedTeam}/${this.selectedRound}`, 'get').then(response => {
          this.loading = false;
          this.scoreCard = response;
          let currentTournament = this.model.tournaments.filter(tournament => {
            return tournament.id == this.selectedTournament;
          });
          this.scoreTournamentPlayMode = _enums.default.playMode[currentTournament[0].playMode];
          this.hasScoreBoard = true;
        }).catch(() => {});
      }
    }
    onEditHandicap(player) {
      this.errors = [];
      this.loading = false;
      this.selectedPlayertoEditHandicap = player;
      this.modals.show('editHandicapModal');
    }
    saveHandicap(modalToClose) {
      this.errors = [];
      this.loading = true;
      this.requests.make(`/handicap`, 'post', {
        playerId: this.selectedPlayertoEditHandicap.playerId,
        teamId: this.selectedTeam,
        tournamentId: this.selectedTournament,
        handicapForHoles: this.selectedPlayertoEditHandicap.playerHandicapOfHole
      }).then(response => {
        this.loading = false;
        this.getScoreCardandShow();
        this.notifications.addNotification('Successfully updated an Entry');
        this.modals.hide(modalToClose);
      }).catch(error => {
        if (error.status === 422) {
          console.log(error);
          this.errors = error.errors;
        }
        this.loading = false;
        throw error;
      });
    }
    onEditTeamHandicap() {
      this.errors = [];
      this.loading = false;
      this.teamHandicap = this.scoreCard.teamGamePlayerShots.teamHandicap;
      this.modals.show('editTeamHandicapModal');
    }
    saveTeamHandicap(modalToClose) {
      this.errors = [];
      this.loading = true;
      this.requests.make(`/handicap/team`, 'post', {
        teamId: this.selectedTeam,
        tournamentId: this.selectedTournament,
        handicap: this.teamHandicap
      }).then(response => {
        this.loading = false;
        this.getScoreCardandShow();
        this.notifications.addNotification('Successfully updated an Entry');
        this.modals.hide(modalToClose);
      }).catch(error => {
        if (error.status === 422) {
          console.log(error);
          this.errors = error.errors;
        }
        this.loading = false;
        throw error;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modals", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "requests", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedTournament", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedTeam", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedTeamName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedRound", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "teams", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "rounds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "hasScoreBoard", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "scoreCard", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "scoreTournamentPlayMode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "selectedPlayertoEditHandicap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "teamHandicap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "tournamentChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "tournamentChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teamChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "teamChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "roundChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "roundChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEditHandicap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onEditHandicap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveHandicap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveHandicap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEditTeamHandicap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onEditTeamHandicap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTeamHandicap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveTeamHandicap"), _class.prototype)), _class);
});