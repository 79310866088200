define("tournament-frontend/templates/admin", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "SP0lHHzW",
    "block": "[[[1,[28,[35,0],[\"Admin\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[10,\"main\"],[14,0,\"main-content position-relative max-height-vh-100 h-100 border-radius-lg \"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container-fluid py-4\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"toggle-sidebar-button\"],[14,\"onclick\",\"toggleSidenav()\"],[12],[1,\"\\n      \"],[10,\"i\"],[14,0,\"material-icons opacity-10\"],[12],[1,\"menu\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row mb-4\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col mb-4\"],[12],[1,\"\\n        \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"page-title\",\"navbar\",\"component\",\"-outlet\"]]",
    "moduleName": "tournament-frontend/templates/admin.hbs",
    "isStrictMode": false
  });
});