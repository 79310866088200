define("tournament-frontend/templates/signin", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "rLUeG1C7",
    "block": "[[[1,[28,[35,0],[\"Signin\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null]],[],false,[\"page-title\",\"signin\"]]",
    "moduleName": "tournament-frontend/templates/signin.hbs",
    "isStrictMode": false
  });
});