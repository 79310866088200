define("tournament-frontend/controllers/admin/xcupseries-leaderboard", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global chart */

  class AdminXCupSeriesController extends _controller.default {}
  _exports.default = AdminXCupSeriesController;
});