define("tournament-frontend/services/requests", ["exports", "@ember/service", "fetch", "tournament-frontend/constants"], function (_exports, _service, _fetch, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let doubleDigit = num => {
    if (num < 10) {
      return '0' + num;
    }
    return num;
  };
  let RequestsService = _exports.default = (_class = class RequestsService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "auth", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
    }
    urlComponent(value) {
      return encodeURIComponent(value);
    }
    serializeDate(date) {
      return date.getFullYear() + '-' + doubleDigit(date.getMonth() + 1) + '-' + doubleDigit(date.getDate()) + ' ' + doubleDigit(date.getHours()) + ':' + doubleDigit(date.getMinutes()) + ':00.000';
    }
    async make(path, method, data) {
      let options = {
        method: method,
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        }
      };
      if (method === 'post' || method === 'put') {
        options.body = JSON.stringify(data);
      } else if (method === 'delete') {
        options.body = '{}';
      }
      return this.send(path, method, data, options);
    }
    async makeFormData(path, method, data) {
      let options = {
        method: method,
        mode: 'cors',
        headers: {}
      };
      if (method === 'post' || method === 'put') {
        options.body = data;
      } else if (method === 'delete') {
        options.body = '{}';
      }
      return this.send(path, method, data, options);
    }
    async send(path, method, data, options) {
      if (this.auth.token) {
        options.headers['Authorization'] = `Bearer ${this.auth.token}`;
      }
      let response = await (0, _fetch.default)(_constants.BASE_URL + path, options);
      if (response.status === 400 || response.status === 401) {
        this.auth.logout();
        this.router.transitionTo('signin');
        throw {
          status: response.status,
          errors: []
        };
      }
      let content = await response.json();
      if (response.status === 422) {
        let errors = [];
        for (const [, value] of Object.entries(content)) {
          errors.push(value);
        }
        throw {
          status: response.status,
          errors
        };
        // bad requests will be redirected to the login page
      }
      if (response.status > 400) {
        throw {
          status: 422,
          errors: ['An unexpected error has occurred']
        };
      }

      // global error handling
      return content;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});