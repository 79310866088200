define("tournament-frontend/modifiers/use-element", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberModifier.modifier)(function useElement(element, [register, unregister]) {
    register(element);
    return () => unregister(element);
  });
});