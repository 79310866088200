define("tournament-frontend/models/tournament", ["exports", "@glimmer/tracking", "tournament-frontend/resources/enums", "tournament-frontend/models/common/types"], function (_exports, _tracking, _enums, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let Tournament = _exports.default = (_class = class Tournament {
    constructor({
      id = 0,
      name = '',
      currentRound = 1,
      passCode = '',
      type = 'NATIONAL',
      playMode = 'STROKE',
      courseCode = '$1000_SeoSeoul',
      courseHoleSelection = 'FULL18',
      requiredHandicap = 100,
      dynamics = _types.defaultDynamics,
      courseSettings = _types.defaultCourseSettings,
      calibration = _types.defaultCalibration,
      tees = _types.defaultTees,
      relatedTournament = 0,
      startDate = new Date().setHours(10),
      endDate = new Date().setHours(10),
      xCupSeries = false,
      xCupSeriesType = 'TEAM',
      advertisementUrls = {},
      imageUrls = [],
      iconURL = null,
      rulesUrl = null,
      descriptionUrl = null
    }) {
      _initializerDefineProperty(this, "id", _descriptor, this);
      _initializerDefineProperty(this, "name", _descriptor2, this);
      _initializerDefineProperty(this, "passCode", _descriptor3, this);
      _initializerDefineProperty(this, "currentRound", _descriptor4, this);
      _initializerDefineProperty(this, "type", _descriptor5, this);
      _initializerDefineProperty(this, "playMode", _descriptor6, this);
      _initializerDefineProperty(this, "requiredHandicap", _descriptor7, this);
      _initializerDefineProperty(this, "relatedTournament", _descriptor8, this);
      _initializerDefineProperty(this, "tournamentStatus", _descriptor9, this);
      _initializerDefineProperty(this, "xCupSeries", _descriptor10, this);
      _initializerDefineProperty(this, "advertisementUrls", _descriptor11, this);
      _initializerDefineProperty(this, "imageUrls", _descriptor12, this);
      _initializerDefineProperty(this, "dynamics", _descriptor13, this);
      _initializerDefineProperty(this, "calibration", _descriptor14, this);
      _initializerDefineProperty(this, "courseSettings", _descriptor15, this);
      _initializerDefineProperty(this, "tees", _descriptor16, this);
      _initializerDefineProperty(this, "iconURL", _descriptor17, this);
      _initializerDefineProperty(this, "rulesUrl", _descriptor18, this);
      _initializerDefineProperty(this, "descriptionUrl", _descriptor19, this);
      this.id = id;
      this.name = name;
      this.currentRound = currentRound;
      this.passCode = passCode;
      this.dynamics = dynamics ? dynamics : _types.defaultDynamics;
      this.courseSettings = courseSettings ? courseSettings : _types.defaultCourseSettings;
      this.calibration = calibration ? calibration : _types.defaultCalibration;
      this.type = type;
      this.playMode = playMode;
      this.requiredHandicap = requiredHandicap;
      this.courseCode = courseCode.length == 0 ? '$1000_SeoSeoul' : courseCode;
      this.courseHoleSelection = courseHoleSelection;
      this.tees = tees ? tees : _types.defaultTees;
      this.relatedTournament = relatedTournament;
      this.startDate = new Date(startDate);
      this.endDate = new Date(endDate);
      this.xCupSeries = xCupSeries;
      this.xCupSeriesType = xCupSeriesType;
      var currentTime = new Date();
      if (currentTime > this.endDate) this.tournamentStatus = 'Finished';else if (currentTime < this.startDate) this.tournamentStatus = 'Not Started';else this.tournamentStatus = 'Ongoing';
      this.advertisementUrls = advertisementUrls ? advertisementUrls : {};
      this.imageUrls = imageUrls ? imageUrls : [];
      this.iconURL = iconURL;
      this.rulesUrl = rulesUrl;
      this.descriptionUrl = descriptionUrl;
    }
    get json() {
      return {
        id: this.id,
        name: this.name,
        currentRound: this.currentRound,
        passCode: this.passCode,
        dynamics: this.dynamics,
        courseSettings: this.courseSettings,
        calibration: this.calibration,
        type: this.type,
        playMode: this.playMode,
        requiredHandicap: this.requiredHandicap,
        courseCode: this.courseCode,
        courseHoleSelection: this.courseHoleSelection,
        tees: this.tees,
        relatedTournament: this.relatedTournament,
        startDate: new Date(this.startDate).toISOString().split('T')[0],
        endDate: new Date(this.endDate).toISOString().split('T')[0],
        xCupSeries: this.xCupSeries,
        xCupSeriesType: this.xCupSeriesType,
        advertisementUrls: this.advertisementUrls,
        imageUrls: this.imageUrls,
        iconURL: this.iconURL,
        rulesUrl: this.rulesUrl,
        descriptionUrl: this.descriptionUrl
      };
    }
    get isRegionalTournament() {
      return this.type === 'REGIONAL';
    }
    get isLocalTournament() {
      return this.type === 'LOCAL';
    }
    get getTypeString() {
      return _enums.default.type[this.type];
    }
    get getPlayModeString() {
      return _enums.default.playMode[this.playMode];
    }
    get getCourses() {
      var ret = [];
      for (var prop in _enums.default.courses) {
        ret.push({
          key: prop,
          value: _enums.default.courses[prop]
        });
      }
      return ret;
    }
    get getAdvertisementPlaces() {
      var ret = [];
      for (var prop in _enums.default.advertisementPlaces) {
        ret.push({
          key: prop,
          value: _enums.default.advertisementPlaces[prop]
        });
      }
      return ret;
    }
    get getPlayModes() {
      var ret = [];
      for (var prop in _enums.default.playMode) {
        ret.push({
          key: prop,
          value: _enums.default.playMode[prop]
        });
      }
      return ret;
    }
    get getTeeOptions() {
      var ret = [];
      for (var prop in _enums.default.teeOptions) {
        ret.push({
          key: prop,
          value: _enums.default.teeOptions[prop]
        });
      }
      return ret;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "passCode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentRound", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "playMode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "requiredHandicap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "relatedTournament", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "tournamentStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "xCupSeries", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "advertisementUrls", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "imageUrls", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "dynamics", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        gravity,
        rollingResistance,
        ballSpeedCompensation,
        puttSpeedCompensation,
        sliceSpinCompensation,
        hookSpinCompensation,
        backSpinCompensation,
        forwardSpinCompensation
      };
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "calibration", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        ballSpeed,
        puttingSpeed,
        backSpinOffset,
        sideSpinOffset,
        backSpinGain,
        sideSpinGain
      };
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "courseSettings", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        fieldDistance,
        greenDistance,
        speedUnit,
        swingView,
        puttingView,
        xplateGreen,
        xplateField,
        autoBallFeed,
        puttingPreview,
        conceed,
        obTee,
        playableArea,
        doublePar,
        windOption,
        clubSelectionInTheFridge,
        swingInformation,
        fieldTrajectory,
        greenCondition,
        puttingGrid,
        autoFlyBy,
        rough,
        greenSideBunker,
        fairwayBunker
      };
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "tees", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        maleTee,
        femaleTee,
        seniorTee
      };
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "iconURL", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "rulesUrl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "descriptionUrl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});