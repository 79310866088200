define("tournament-frontend/services/modals", ["exports", "@ember/service", "@glimmer/tracking", "@ember/array", "@ember/runloop"], function (_exports, _service, _tracking, _array, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2, _descriptor3;
  /* global bootstrap */
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ModalInstance = (_class = class ModalInstance {
    constructor(modalId, modalInstance) {
      _initializerDefineProperty(this, "modalId", _descriptor, this);
      _initializerDefineProperty(this, "modalInstance", _descriptor2, this);
      this.modalId = modalId;
      this.modalInstance = modalInstance;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modalId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalInstance", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  let ModalsService = _exports.default = (_class2 = class ModalsService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentModals", _descriptor3, this);
    }
    show(modalId) {
      this.currentModals.findBy('modalId', modalId)?.modalInstance.show();
    }
    hide(modalId) {
      this.currentModals.findBy('modalId', modalId)?.modalInstance.hide();
    }
    register(modalId, element) {
      (0, _runloop.next)(() => {
        if (this.currentModals.findBy('modalId', modalId)) {
          throw new Error(`Modal with id ${modalId} already registered`);
        }
        this.currentModals.addObject(new ModalInstance(modalId, new bootstrap.Modal(element)));
      });
    }
    unregister(modalId) {
      this.currentModals.removeObject(this.currentModals.findBy('modalId', modalId));
    }
  }, (_descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "currentModals", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  })), _class2);
});