define("tournament-frontend/helpers/disable-editing-tournament", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function disableEditingTournament(positional /*, named*/) {
    if (positional[0].isSuperAdmin || positional[0].isRegionalRepresentative && positional[1].type == 'REGIONAL' || positional[0].isStoreOwner && positional[1].type == 'LOCAL') {
      return false;
    }
    return true;
  });
});