define("tournament-frontend/helpers/date-format", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function dateFormat(positional /*, named*/) {
    return positional[0].toLocaleDateString('en-US');
  });
});