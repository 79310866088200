define("tournament-frontend/components/confirmation-modal", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="modal fade" id={{@modalId}} data-backdrop="static" tabindex="-1" aria-hidden="true" {{use-element
      this.register this.unregister}}>
  
      <div class="modal-dialog {{@class}}">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
              </div>
              <div class="modal-body">
                  {{#if @errors.length}}
                  <div class="alert alert-danger text-white fw-normal">
                      {{#each @errors as |error|}}
                      <div>{{error}}</div>
                      {{/each}}
                  </div>
                  {{/if}}
                  {{@text}}
                  {{yield}}
              </div>
              <div class="modal-footer">
                  <button disabled={{@loading}} type="button" class="btn btn-secondary"
                      data-bs-dismiss="modal">Cancel</button>
                  <button disabled={{@loading}} type="button" class="btn btn-success" {{on "click"
                      @onSave}}>Delete</button>
              </div>
          </div>
      </div>
  </div>
  */
  {
    "id": "VRS1XA6x",
    "block": "[[[11,0],[24,0,\"modal fade\"],[16,1,[30,1]],[24,\"data-backdrop\",\"static\"],[24,\"tabindex\",\"-1\"],[24,\"aria-hidden\",\"true\"],[4,[38,0],[[30,0,[\"register\"]],[30,0,[\"unregister\"]]],null],[12],[1,\"\\n\\n    \"],[10,0],[15,0,[29,[\"modal-dialog \",[30,2]]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"modal-content\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"modal-header\"],[12],[1,\"\\n                \"],[10,\"h5\"],[14,0,\"modal-title\"],[14,1,\"exampleModalLabel\"],[12],[1,\"Confirmation\"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"modal-body\"],[12],[1,\"\\n\"],[41,[30,3,[\"length\"]],[[[1,\"                \"],[10,0],[14,0,\"alert alert-danger text-white fw-normal\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,3]],null]],null],null,[[[1,\"                    \"],[10,0],[12],[1,[30,4]],[13],[1,\"\\n\"]],[4]],null],[1,\"                \"],[13],[1,\"\\n\"]],[]],null],[1,\"                \"],[1,[30,5]],[1,\"\\n                \"],[18,8,null],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n                \"],[10,\"button\"],[15,\"disabled\",[30,6]],[14,0,\"btn btn-secondary\"],[14,\"data-bs-dismiss\",\"modal\"],[14,4,\"button\"],[12],[1,\"Cancel\"],[13],[1,\"\\n                \"],[11,\"button\"],[16,\"disabled\",[30,6]],[24,0,\"btn btn-success\"],[24,4,\"button\"],[4,[38,5],[\"click\",[30,7]],null],[12],[1,\"Delete\"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@modalId\",\"@class\",\"@errors\",\"error\",\"@text\",\"@loading\",\"@onSave\",\"&default\"],false,[\"use-element\",\"if\",\"each\",\"-track-array\",\"yield\",\"on\"]]",
    "moduleName": "tournament-frontend/components/confirmation-modal.hbs",
    "isStrictMode": false
  });
  let ConfirmationModalComponent = _exports.default = (_class = class ConfirmationModalComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "modals", _descriptor, this);
    }
    register(element) {
      this.modals.register(this.args.modalId, element);
    }
    unregister() {
      this.modals.unregister(this.args.modalId);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modals", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "register", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unregister", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unregister"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ConfirmationModalComponent);
});