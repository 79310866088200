define("tournament-frontend/helpers/eq2", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function eq2(positional /*, named*/) {
    return positional[0][positional[1]] == positional[2] || positional[0]["'" + positional[1] + "'"] == positional[2];
  });
});