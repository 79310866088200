define("tournament-frontend/helpers/get-by-id", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function getById(positional /*, named*/) {
    return positional[0].find(item => item.id === positional[1]);
  });
});