define("tournament-frontend/helpers/ifCond", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function ifCond(positional /*, named*/) {
    let operator = positional[1];
    var v1 = positional[0];
    var v2 = positional[2];
    switch (operator) {
      case ('!=', '!=='):
        return v1 != v2;
      case '<':
        return v1 < v2;
      case '<=':
        return v1 <= v2;
      case '>':
        return v1 > v2;
      case '>=':
        return v1 >= v2;
      case ('&&', 'and'):
        return v1 && v2;
      case ('||', 'or'):
        return v1 || v2;
      default:
        return false;
    }
  });
});