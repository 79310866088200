define("tournament-frontend/resources/enums", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userRole = _exports.type = _exports.tenShotChallengeType = _exports.teeOptions = _exports.playMode = _exports.courses = _exports.challengeType = _exports.challengeHoles = _exports.advertisementPlaces = void 0;
  const type = _exports.type = {
    NATIONAL: 'National',
    REGIONAL: 'Regional',
    LOCAL: 'local'
  };
  const challengeType = _exports.challengeType = {
    NATIONAL_CHALLENGE: 'National Challenge',
    MINI_GAMES: 'Mini Games'
  };
  const tenShotChallengeType = _exports.tenShotChallengeType = {
    STANDARD: 'STANDARD',
    ONECLUB: 'ONECLUB',
    PRO: 'PRO'
  };
  const playMode = _exports.playMode = {
    STROKE: 'Stroke',
    PAR3GAME: 'Par 3',
    MATCH_BEST_BALL: 'Best Ball',
    MATCH_BEST_BALL_GROSS: 'Best Ball Gross',
    MATCH_ALTERNATE_SHOT: 'Alternate Shot',
    MATCH_SKINS: 'Skins',
    EVENT_NEARPIN: 'Near Pin',
    EVENT_LONGEST: 'Longest',
    EVENT_PUTTING: 'Putting',
    TRAINING_DRIVING_RANGE: 'Driving Range Training',
    TRAINING_COURSE: 'Course Training',
    SCRAMBLE: 'Scramble'
  };
  const userRole = _exports.userRole = {
    SUPER_ADMIN: 'Super Admin',
    REGIONAL_REPRESENTATIVE: 'Regional Representative',
    STORE_OWNER: 'Store Owner',
    TOURNAMENT_ADMIN: 'Tournament Admin',
    BI_ADMIN: 'BI User'
  };
  const teeOptions = _exports.teeOptions = {
    Blue: 'Blue',
    White: 'White',
    Red: 'Red',
    Gold: 'Gold'
  };
  const courses = _exports.courses = {
    $1000_SeoSeoul: 'Seo Seoul',
    $1001_Montvert: 'Montvert',
    $1002_SilkRiver: 'Silk River',
    $1003_TongDo: 'Tong Do',
    $1004_Ora: 'Ora',
    $1005_MaunaOcean: 'Mauna Ocean',
    $1006_PristineValley: 'Pristine Valley',
    $1007_HwaSan: 'Hwa San',
    $1008_Muju: 'Muju',
    $1009_Sky72Sky: 'Sky 72 Sky',
    $1010_Jisan: 'Jisan',
    $1011_PineBeach: 'Pine Beach',
    $1012_Asiana: 'Asiana',
    $1015_InterBurgo: 'Inter Burgo',
    $1016_JgolfKasumi: 'Jgolf Kasumi',
    $1017_Sky72Ocean: 'Sky 72 Ocean',
    $1018_SunHills: 'Sun Hills',
    $1019_LakeWood: 'Lake Wood',
    $1020_Bethpage: 'Bethpage',
    $1021_Metropolitan: 'Metropolitan',
    $1022_HabourTown: 'Habour Town',
    $1023_BayHill: 'Bay Hill',
    $1024_EvianMasters: 'Evian Masters',
    $1025_Pebblebeach: 'Pebble Beach',
    $1026_AugustaNational: 'Augusta National',
    $1027_CoeurdAlene: 'Coeurd Alene',
    $1028_Innisbrook: 'Innisbrook',
    $1029_Spanishbay: 'Spanish bay',
    $1030_PGA: 'PGA',
    $1031_Spyglasshill: 'Spy Glass Hill',
    $1032_KapaLua: 'Kapa Lua',
    $1033_Doral: 'Doral',
    $1034_EASTLAKE: 'East Lake',
    $1035_TurtleBay: 'Turtle Bay',
    $1036_PrinceVille: 'Prince Ville',
    $1037_Hazeltine: 'Hazeltine',
    $1038_KawanaHotelFuji: 'Kawana Hotal Fuji',
    $1039_PebbleBeachGL: 'Pebble Beach GL',
    $1040_JapanMemorial: 'Japan Memorial',
    $1041_XgolfCC: 'Xgolf CC',
    $1042_RoyalPines: 'Royal Pines',
    $1043_Kingston: 'Kingston',
    $1044_OakMont: 'Oak Mont',
    $1045_Teecloud: 'Tee Cloud',
    $1046_Piratesisland: 'Pirates Island',
    $2000_Indoor_00: 'Indoor',
    $4001_OldCourse: 'Old Course'
  };
  const advertisementPlaces = _exports.advertisementPlaces = {
    AD_ID_0: 'Loading',
    AD_ID_1: 'Game Type - Top',
    AD_ID_2: 'Game Type - Bottom',
    AD_ID_3: 'Play Mode - Top',
    AD_ID_4: 'Play Mode - Bottom',
    AD_ID_5: 'Play Mode_2 - Top',
    AD_ID_6: 'Play Mode_2 - Bottom',
    AD_ID_7: 'Settings Top',
    AD_ID_8: 'Settings Bottom',
    AD_ID_9: 'Tournament',
    AD_ID_10: 'Practice Top',
    AD_ID_11: 'Practice Bottom',
    AD_ID_12: 'Game Top',
    AD_ID_13: 'Game Bottom',
    AD_ID_14: 'Score Card Top',
    AD_ID_15: 'Score Card Bottom Left',
    AD_ID_16: 'Score Card Bottom',
    AD_ID_17: 'Player'
  };
  const challengeHoles = _exports.challengeHoles = {
    $1039_Challenge_10_Shot_0: '10 Shot Challenge',
    $4001_Challenge_10_Shot_0: '10 shot challenge St Andrews One Club',
    //$1000_SeoSeoul: 'Seo Seoul',
    //$1001_Montvert: 'Montvert',
    //$1002_SilkRiver: 'Silk River',
    //$1003_TongDo: 'TongDo',
    //$1004_Ora: 'Ora',
    $1005_MaunaOcean_15: '15 @ Maura Ocean',
    //$1006_PristineValley: 'Pristine Valley',
    //$1007_HwaSan: 'HwaSan',
    //$1008_Muju: 'Muju',
    //$1009_Sky72Sky: 'Sky72Sky',
    //$1010_Jisan: 'Jisan',
    $1011_PineBeach_15: '15 @ Pine Beach',
    //$1012_Asiana: 'Asiana',
    //$1015_InterBurgo: 'Inter Burgo',
    //$1016_JgolfKasumi: 'Jgolf Kasumi',
    //$1017_Sky72Ocean: 'Sky 72 Ocean',
    //$1018_SunHills: 'Sun Hills',
    //$1019_LakeWood: 'LakeWood',
    $1020_Bethpage_17: '17 @ Bethpage',
    //$1021_Metropolitan: 'Metropolitan',
    $1022_HabourTown_14: '14 @ Harbour Town',
    $1023_BayHill_17: '17 @ Bay Hill',
    //$1024_EvianMasters: 'EvianMasters',
    $1025_Pebblebeach_7: '7 @ Pebble',
    //$1026_AugustaNational: 'Augusta National',
    //$1027_CoeurdAlene: 'Coeurd Alene',
    $1028_Innisbrook_17: '17 @ Innisbrook',
    //$1029_Spanishbay: 'Spanish bay',
    $1030_PGA_17: '17 @ PGA National',
    //$1031_Spyglasshill: 'Spyglass hill',
    //$1032_KapaLua: 'KapaLua',
    //$1033_Doral: 'Doral',
    $1034_EASTLAKE_18: '18 @ East Lake',
    //$1035_TurtleBay: 'Turtle Bay',
    //$1036_PrinceVille: 'PrinceVille',
    //$1037_Hazeltine: 'Hazeltine',
    //$1038_KawanaHotelFuji: 'Kawana Hotel Fuji',
    //$1039_PebbleBeachGL: 'Pebble Beach GL',
    $1040_JapanMemorial_17: '17 @ Japan Memorial',
    //$1041_XgolfCC: 'XgolfCC',
    $1042_RoyalPines_16: '16 @ Royal Pines',
    //$1043_Kingston: 'Kingston',
    $1044_OakMont_8: '8 @ Oakmont ',
    //$1045_Teecloud: 'Tee cloud',
    $1046_Challenge_10_Shot_0: '10-Putt Challenge Pirates Island'
    //$2000_Indoor_00: 'Indoor 00',
    //$4001_Old_Course: 'Old Course',
  };
});