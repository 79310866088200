define("tournament-frontend/templates/challengeleaderboard", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2m2Fm0rz",
    "block": "[[[1,[28,[35,0],[\"Leaderboard\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"p-4\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[30,0,[\"model\",\"challenge\",\"displayName\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-lg-12\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"card mt-3\"],[12],[1,\"\\n        \"],[10,\"h3\"],[14,0,\"ms-sm-3\"],[12],[1,\"Score\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"table-responsive\"],[12],[1,\"\\n          \"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n            \"],[10,\"thead\"],[14,5,\"border-bottom: 2px solid #ddd\"],[12],[1,\"\\n              \"],[10,\"th\"],[12],[1,\"Position\"],[13],[1,\"\\n              \"],[10,\"th\"],[12],[1,\"Player\"],[13],[1,\"\\n              \"],[10,\"th\"],[12],[1,\"Score\"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\",\"scores\"]]],null]],null],null,[[[1,\"              \"],[10,\"tr\"],[14,5,\"border-bottom: 1px solid #ddd\"],[12],[1,\"\\n                \"],[10,\"td\"],[12],[1,[28,[35,3],[[30,2]],null]],[13],[1,\"\\n                \"],[10,\"td\"],[12],[1,[30,1,[\"playerName\"]]],[13],[1,\"\\n                \"],[10,\"td\"],[12],[1,[30,1,[\"score\"]]],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13]],[\"leaderboardScore\",\"index\"],false,[\"page-title\",\"each\",\"-track-array\",\"plus-one\"]]",
    "moduleName": "tournament-frontend/templates/challengeleaderboard.hbs",
    "isStrictMode": false
  });
});