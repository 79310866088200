define("tournament-frontend/services/auth", ["exports", "@ember/service", "@glimmer/tracking"], function (_exports, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let User = (_class = class User {
    constructor({
      id,
      email,
      dailyCaloricLimit,
      roleType
    }) {
      _initializerDefineProperty(this, "id", _descriptor, this);
      _initializerDefineProperty(this, "email", _descriptor2, this);
      _initializerDefineProperty(this, "dailyCaloricLimit", _descriptor3, this);
      _initializerDefineProperty(this, "roleType", _descriptor4, this);
      this.id = id;
      this.email = email;
      this.dailyCaloricLimit = dailyCaloricLimit;
      this.roleType = roleType;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "email", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dailyCaloricLimit", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "roleType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  let AuthService = _exports.default = (_class2 = class AuthService extends _service.default {
    get supportsLocalStorage() {
      return !!localStorage && typeof localStorage.getItem === 'function' && typeof localStorage.setItem === 'function' && typeof localStorage.removeItem === 'function';
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "requests", _descriptor5, this);
      _initializerDefineProperty(this, "token", _descriptor6, this);
      _initializerDefineProperty(this, "user", _descriptor7, this);
      _initializerDefineProperty(this, "loggingIn", _descriptor8, this);
      if (this.supportsLocalStorage) {
        this.token = localStorage.getItem('token');
        if (localStorage.getItem('user') !== null) {
          this.user = new User(JSON.parse(localStorage.getItem('user')));
        }
      }
    }
    get isSuperAdmin() {
      return this.user && this.user.roleType === 'SUPER_ADMIN';
    }
    get isRegionalRepresentative() {
      return this.user && this.user.roleType === 'REGIONAL_REPRESENTATIVE';
    }
    get isStoreOwner() {
      return this.user && this.user.roleType === 'STORE_OWNER';
    }
    get isBIAdmin() {
      return this.user && this.user.roleType === 'BI_ADMIN';
    }
    get isTournamentAdmin() {
      return this.user && this.user.roleType === 'TOURNAMENT_ADMIN';
    }
    logout() {
      this.token = null;
      this.user = null;
      if (this.supportsLocalStorage) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
    }
    async signin(email, password) {
      this.token = null;
      this.user = null;
      this.loggingIn = true;
      let loginResponse = await this.requests.make('/login', 'post', {
        email,
        password
      }).catch(e => {
        this.loggingIn = false;
        throw e;
      });
      this.token = loginResponse.token;
      this.loggingIn = false;
      this.user = new User(loginResponse.user);
      if (this.supportsLocalStorage) {
        localStorage.setItem('token', this.token);
        localStorage.setItem('user', JSON.stringify(loginResponse.user));
      }
    }
  }, (_descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "requests", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "token", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "user", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "loggingIn", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2);
});