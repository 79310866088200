define("tournament-frontend/constants", ["exports", "tournament-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BASE_URL = void 0;
  const BASE_URL = _exports.BASE_URL = _environment.default.APP.BASE_URL;
  // Comment out for development
  // export const BASE_URL = 'http://localhost:6868';

  // Comment out for live
  // export const BASE_URL = 'https://tournament.xgolftech.com';

  // Comment out for sandbox environment
  // export const BASE_URL = 'https://tournament-sandbox.xgolftech.com';
});