define("tournament-frontend/models/challenge", ["exports", "@glimmer/tracking", "@material-ui/core", "tournament-frontend/resources/enums", "tournament-frontend/models/common/types"], function (_exports, _tracking, _core, _enums, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let Challenge = _exports.default = (_class = class Challenge {
    constructor({
      id = 0,
      name = '',
      startDate = new Date().setHours(10),
      endDate = new Date().setHours(10),
      numberOfShots = 0,
      cost = 0,
      distance = 0,
      holeIndex = 0,
      description = '',
      type = '',
      challengeType = '',
      tenShotChallengeType = '',
      minParticipants = 0,
      maxParticipants = 0,
      dynamics = _types.defaultDynamics,
      courseSettings = _types.defaultCourseSettings,
      calibration = _types.defaultCalibration,
      tees = _types.defaultTees,
      advertisementUrls = {},
      imageUrls = [],
      iconURL = null
    }) {
      _initializerDefineProperty(this, "id", _descriptor, this);
      _initializerDefineProperty(this, "name", _descriptor2, this);
      _initializerDefineProperty(this, "startDate", _descriptor3, this);
      _initializerDefineProperty(this, "endDate", _descriptor4, this);
      _initializerDefineProperty(this, "numberOfShots", _descriptor5, this);
      _initializerDefineProperty(this, "cost", _descriptor6, this);
      _initializerDefineProperty(this, "distance", _descriptor7, this);
      _initializerDefineProperty(this, "holeIndex", _descriptor8, this);
      _initializerDefineProperty(this, "description", _descriptor9, this);
      _initializerDefineProperty(this, "type", _descriptor10, this);
      _initializerDefineProperty(this, "challengeType", _descriptor11, this);
      _initializerDefineProperty(this, "tenShotChallengeType", _descriptor12, this);
      _initializerDefineProperty(this, "minParticipants", _descriptor13, this);
      _initializerDefineProperty(this, "maxParticipants", _descriptor14, this);
      _initializerDefineProperty(this, "advertisementUrls", _descriptor15, this);
      _initializerDefineProperty(this, "imageUrls", _descriptor16, this);
      _initializerDefineProperty(this, "dynamics", _descriptor17, this);
      _initializerDefineProperty(this, "calibration", _descriptor18, this);
      _initializerDefineProperty(this, "courseSettings", _descriptor19, this);
      _initializerDefineProperty(this, "tees", _descriptor20, this);
      _initializerDefineProperty(this, "iconURL", _descriptor21, this);
      this.id = id;
      this.name = name;
      this.startDate = new Date(startDate);
      this.endDate = new Date(endDate);
      this.numberOfShots = numberOfShots;
      this.cost = cost;
      this.distance = distance;
      this.holeIndex = holeIndex;
      this.description = description;
      this.type = type;
      this.challengeType = challengeType;
      this.minParticipants = minParticipants;
      this.maxParticipants = maxParticipants;
      this.dynamics = dynamics ? dynamics : _types.defaultDynamics;
      this.courseSettings = courseSettings ? courseSettings : _types.defaultCourseSettings;
      this.calibration = calibration ? calibration : _types.defaultCalibration;
      this.tees = tees ? tees : _types.defaultTees;
      this.advertisementUrls = advertisementUrls ? advertisementUrls : {};
      this.imageUrls = imageUrls ? imageUrls : [];
      this.iconURL = iconURL;
      this.courseAndHole = name + '_' + holeIndex;
      this.displayName = _enums.default.challengeHoles[this.courseAndHole];
      this.tenShotChallengeType = tenShotChallengeType;
    }
    get json() {
      return {
        id: this.id,
        name: this.name,
        startDate: new Date(this.startDate).toISOString().split('T')[0],
        endDate: new Date(this.endDate).toISOString().split('T')[0],
        numberOfShots: this.numberOfShots,
        cost: this.cost,
        distance: this.distance,
        holeIndex: this.holeIndex,
        description: this.description,
        type: this.type,
        challengeType: this.challengeType,
        tenShotChallengeType: this.tenShotChallengeType,
        dynamics: this.dynamics,
        courseSettings: this.courseSettings,
        calibration: this.calibration,
        tees: this.tees,
        advertisementUrls: this.advertisementUrls,
        imageUrls: this.imageUrls,
        minParticipants: this.minParticipants,
        maxParticipants: this.maxParticipants,
        iconURL: this.iconURL
      };
    }
    get getTypeOptions() {
      var ret = [];
      for (var prop in _enums.default.type) {
        ret.push({
          key: prop,
          value: _enums.default.type[prop]
        });
      }
      return ret;
    }
    get getChallengeTypeOptions() {
      var ret = [];
      for (var prop in _enums.default.challengeType) {
        ret.push({
          key: prop,
          value: _enums.default.challengeType[prop]
        });
      }
      return ret;
    }
    get getTenShotChallengeTypeOptions() {
      var ret = [];
      for (var prop in _enums.default.tenShotChallengeType) {
        ret.push({
          key: prop,
          value: _enums.default.tenShotChallengeType[prop]
        });
      }
      return ret;
    }
    get getAdvertisementPlaces() {
      var ret = [];
      for (var prop in _enums.default.advertisementPlaces) {
        ret.push({
          key: prop,
          value: _enums.default.advertisementPlaces[prop]
        });
      }
      return ret;
    }
    get getTeeOptions() {
      var ret = [];
      for (var prop in _enums.default.teeOptions) {
        ret.push({
          key: prop,
          value: _enums.default.teeOptions[prop]
        });
      }
      return ret;
    }
    get getChallengeCourses() {
      var ret = [];
      for (var prop in _enums.default.challengeHoles) {
        ret.push({
          key: prop,
          value: _enums.default.challengeHoles[prop]
        });
      }
      return ret;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "numberOfShots", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cost", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "distance", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "holeIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "description", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "challengeType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "tenShotChallengeType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "minParticipants", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "maxParticipants", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "advertisementUrls", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "imageUrls", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "dynamics", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        gravity,
        rollingResistance,
        ballSpeedCompensation,
        puttSpeedCompensation,
        sliceSpinCompensation,
        hookSpinCompensation,
        backSpinCompensation,
        forwardSpinCompensation
      };
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "calibration", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        ballSpeed,
        puttingSpeed,
        backSpinOffset,
        sideSpinOffset,
        backSpinGain,
        sideSpinGain
      };
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "courseSettings", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        fieldDistance,
        greenDistance,
        speedUnit,
        swingView,
        puttingView,
        xplateGreen,
        xplateField,
        autoBallFeed,
        puttingPreview,
        conceed,
        obTee,
        playableArea,
        doublePar,
        windOption,
        clubSelectionInTheFridge,
        swingInformation,
        fieldTrajectory,
        greenCondition,
        puttingGrid,
        autoFlyBy,
        rough,
        greenSideBunker,
        fairwayBunker
      };
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "tees", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        maleTee,
        femaleTee,
        seniorTee
      };
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "iconURL", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});