define("tournament-frontend/controllers/admin/admins-list", ["exports", "@ember/controller", "@ember/object", "@ember/service", "tournament-frontend/models/admin", "@glimmer/tracking"], function (_exports, _controller, _object, _service, _admin, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdminAdminsListController = _exports.default = (_class = class AdminAdminsListController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "modals", _descriptor, this);
      _initializerDefineProperty(this, "requests", _descriptor2, this);
      _initializerDefineProperty(this, "notifications", _descriptor3, this);
      _initializerDefineProperty(this, "admin", _descriptor4, this);
      _initializerDefineProperty(this, "originalAdmin", _descriptor5, this);
      _initializerDefineProperty(this, "loading", _descriptor6, this);
      _initializerDefineProperty(this, "errors", _descriptor7, this);
    }
    onStartNewAdmin() {
      this.errors = [];
      this.loading = false;
      this.admin = new _admin.default({});
      this.modals.show('newAdminModal');
    }
    onStartDeletingAdmin(admin) {
      this.errors = [];
      this.loading = false;
      this.admin = admin;
      this.modals.show('deleteAdminModal');
    }
    onStartEditingAdmin(admin) {
      this.errors = [];
      this.loading = false;
      this.originalAdmin = admin;
      this.admin = new _admin.default(admin);
      this.modals.show('updateAdminModal');
    }
    newAdmin() {
      this.errors = [];
      this.loading = true;
      this.requests.make(`/admins`, 'post', this.admin.json).then(response => {
        this.loading = false;
        this.model.admins.addObject(new _admin.default(response));
        this.notifications.addNotification('Successfully created an Admin');
        this.modals.hide('newAdminModal');
      }).catch(error => {
        if (error.status === 422) {
          this.errors = error.errors;
        }
        this.loading = false;
        throw error;
      });
    }
    updateAdmin() {
      this.errors = [];
      this.loading = true;
      this.requests.make(`/admins`, 'put', this.admin.json).then(response => {
        this.loading = false;
        this.model.admins.replace(this.model.admins.indexOf(this.originalAdmin), 1, [new _admin.default(response)]);
        this.notifications.addNotification('Successfully updated an Entry');
        this.modals.hide('updateAdminModal');
      }).catch(error => {
        if (error.status === 422) {
          this.errors = error.errors;
        }
        this.loading = false;
        throw error;
      });
    }
    selectRoleType(event) {
      this.admin.roleType = event.target.value;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modals", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "requests", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "admin", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "originalAdmin", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onStartNewAdmin", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onStartNewAdmin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onStartDeletingAdmin", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onStartDeletingAdmin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onStartEditingAdmin", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onStartEditingAdmin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newAdmin", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newAdmin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAdmin", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateAdmin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectRoleType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectRoleType"), _class.prototype)), _class);
});