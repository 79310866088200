define("tournament-frontend/services/bi-dashboard", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let BidashboardService = _exports.default = (_class = class BidashboardService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "requests", _descriptor, this);
    }
    // number of players
    getNumberOfPlayersBoardData(response) {
      return {
        labels: ['Weekday', 'Weeknight', 'Weekend'],
        datasets: [{
          data: [response ? response.weekDayStat.total : 0, response ? response.weekNightStat.total : 0, response ? response.weekendStat.total : 0],
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    async getNumberOfPlayers(startDate, endDate) {
      let response = await this.requests.make(startDate != null && endDate != null ? `/bi-dashboard/number-of-players?startDate=${startDate}&endDate=${endDate}` : `/bi-dashboard/number-of-players`, 'get');
      return response;
    }

    // number of plays per month
    getNumberOfPlaysPerMonthBoardData(response) {
      return {
        labels: response?.map(x => x.month),
        datasets: [{
          data: response?.map(x => x.count),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    async getNumberOfPlaysPerMonth(startDate, endDate) {
      let response = await this.requests.make(startDate != null && endDate != null ? `/bi-dashboard/number-of-plays-per-month?startDate=${startDate}&endDate=${endDate}` : `/bi-dashboard/number-of-plays-per-month`, 'get');
      return response;
    }

    // most played courses
    getMostPlayedCoursesBoardData(response) {
      return {
        labels: response?.map(x => x.courseName).slice(0, 12),
        datasets: [{
          label: 'Games Per Course',
          data: response?.map(x => x.countPerCourse).slice(0, 12),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    async getMostPlayedCourses(startDate, endDate) {
      let response = await this.requests.make(startDate != null && endDate != null ? `/bi-dashboard/games-per-course?startDate=${startDate}&endDate=${endDate}` : `/bi-dashboard/games-per-course`, 'get');
      return response;
    }

    // most played game modes
    getMostPopularGameModesBoardData(response) {
      return {
        labels: response?.map(x => x.playMode),
        datasets: [{
          label: '',
          data: response?.map(x => x.countPerMode),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    async getMostPopularGameModes(startDate, endDate) {
      let response = await this.requests.make(startDate != null && endDate != null ? `/bi-dashboard/games-per-mode?startDate=${startDate}&endDate=${endDate}` : `/bi-dashboard/games-per-mode`, 'get');
      return response;
    }

    // tournament tee settings
    getTournamentTeeSettingsBoardData(response) {
      var femaleTeeSetting = response.find(obj => obj.teeTypeName === 'Female');
      var maleTeeSetting = response.find(obj => obj.teeTypeName === 'Male');
      var seniorTeeSetting = response.find(obj => obj.teeTypeName === 'Senior');
      this.teeSettingsLabels = femaleTeeSetting.teeColors?.map(x => x.teeColorName);
      return {
        labels: this.teeSettingsLabels,
        datasets: [{
          label: 'Female',
          data: femaleTeeSetting.teeColors?.map(x => x.totalOccurrence),
          borderColor: 'rgba(0, 112, 65)',
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }, {
          label: 'Male',
          data: maleTeeSetting.teeColors?.map(x => x.totalOccurrence),
          borderColor: 'rgba(234, 134, 0)',
          backgroundColor: 'rgba(234, 134, 0, 0.8)'
        }, {
          label: 'Senior',
          data: seniorTeeSetting.teeColors?.map(x => x.totalOccurrence),
          borderColor: 'rgba(245, 221, 139)',
          backgroundColor: 'rgba(245, 221, 139, 0.8)'
        }]
      };
    }
    async getTournamentTeeSettings(startDate, endDate) {
      let response = await this.requests.make(startDate != null && endDate != null ? `/bi-dashboard/tournament-tee-settings?startDate=${startDate}&endDate=${endDate}` : `/bi-dashboard/tournament-tee-settings`, 'get');
      return response;
    }

    // team number per tournament
    getTeamNumberPerTournamentBoardData(response) {
      return {
        labels: response?.map(x => x.tournamentName),
        datasets: [{
          label: '# of Teams',
          data: response?.map(x => x.teamCount),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    async getTeamNumberPerTournament(startDate, endDate) {
      let response = await this.requests.make(startDate != null && endDate != null ? `/bi-dashboard/team-number-per-tournament?startDate=${startDate}&endDate=${endDate}` : `/bi-dashboard/team-number-per-tournament`, 'get');
      return response;
    }

    // average time per hole
    getAverageTimePerHoleBoardData(response) {
      return {
        labels: response?.map((x, index) => 'Hole ' + (index + 1)).slice(0, 18),
        datasets: [{
          data: response?.map(x => x.averageDuration).slice(0, 18),
          backgroundColor: 'rgba(0, 112, 65, 0.8)'
        }]
      };
    }
    async getAverageTimePerHole(startDate, endDate) {
      let response = await this.requests.make(startDate != null && endDate != null ? `/bi-dashboard/hole-durations?startDate=${startDate}&endDate=${endDate}` : `/bi-dashboard/hole-durations`, 'get');
      return response;
    }

    // tournament active days
    getTournamentActiveDaysBoardData(response) {
      var firstElement = Array.from(response)[0];
      if (firstElement == null) {
        return null;
      } else {
        this.activeTournamentName = firstElement.tournamentId;
        var firstDay = firstElement.day;
        var firstPartSize = 0;
        var fullPartSize = Object.keys(response).length;
        for (let i = 1; i < fullPartSize; i++) {
          var element = Array.from(response)[i];
          if (element.day == firstDay) {
            firstPartSize = i;
          }
        }
        if (firstPartSize > 0) {
          return {
            labels: response?.map(x => x.day).slice(0, firstPartSize),
            datasets: [{
              data: response?.map(x => x.numberOfGames).slice(0, firstPartSize),
              backgroundColor: 'rgba(0, 112, 65, 0.8)'
            }, {
              data: response?.map(x => x.numberOfGames).slice(firstPartSize + 1, fullPartSize),
              backgroundColor: 'rgba(45, 41, 38, 0.8)'
            }]
          };
        } else {
          return {
            labels: response?.map(x => x.day),
            datasets: [{
              data: response?.map(x => x.numberOfGames),
              backgroundColor: 'rgba(0, 112, 65, 0.8)'
            }]
          };
        }
      }
    }
    async getTournamentActiveDays(startDate, tournamentId) {
      let response = await this.requests.make(startDate != null && tournamentId != null ? `/bi-dashboard/tournament-active-days-detailed?startDate=${startDate}&tournamentId=${tournamentId}` : `/bi-dashboard/tournament-active-days-detailed`, 'get');
      return response;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "requests", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});